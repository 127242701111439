import React from "react"
import PropTypes from "prop-types"

import { Faq } from "../../Shared"

export const FaqSection = ({ faqs }) => (
  <section className="background-grey-lightest">
    <section className="wrapper-width wrapper-height faq-section">
      <h2 className="heading-large">Frequently asked questions</h2>
      <div className="faqs">
        {faqs.map((faq) => (
          <Faq key={faq.id} question={faq.question}>
            {faq.answer.answer}
          </Faq>
        ))}
      </div>
    </section>
  </section>
)

FaqSection.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.object.isRequired,
    }).isRequired
  ).isRequired,
}
