import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

import { Review } from "./Review"

import GoogleLogo from "../../../images/Google_2015_logo.svg"

export const GoogleReviews = ({ backgroundColour }) => (
  <StaticQuery
    query={graphql`
      query AllCheckaTradeReviews {
        reviews: contentfulCheckatradeDetails(
          contentful_id: { eq: "2QBHibRnGkaXnxPHp4sucH" }
        ) {
          contentful_id
          reviewCount
          reviewRating
          featuredReviews {
            summary {
              summary
            }
            title
            reviewDate
            reviewerLocation
            id
          }
        }
      }
    `}
    render={(data) => (
      <section className={`background-${backgroundColour}`}>
        <section className="wrapper-width wrapper-height checkatrade-reviews">
          <h2 className="heading-large text-centered">Customer reviews</h2>
          <a
            className="checkatrade-reviews-header"
            href="https://www.google.com/maps/place/HomeAssist.services/@54.9895021,-1.607932,17z/data=!3m1!5s0x487e70d98949efd3:0x1a31560bb1a59a76!4m7!3m6!1s0x487e70d98bfed04b:0x675bb7e509da7f3d!8m2!3d54.9895021!4d-1.6053517!9m1!1b1"
            target="_blank"
            rel="noreferrer"
          >
            {data.reviews.reviewRating && (
              <span className="checkatrade-reviews-header-rating">
                {data.reviews.reviewRating}/5
              </span>
            )}
            <GoogleLogo className="checkatrade-reviews-header-logo" />
            {data.reviews.reviewCount && (
              <span className="checkatrade-reviews-header-count">
                {data.reviews.reviewCount}+ Reviews
              </span>
            )}
          </a>
          <div className="grid-col-3 reviews">
            {data.reviews.featuredReviews.map((review) => (
              <Review
                key={review.id}
                title={review.title}
                summary={review.summary.summary}
                date={review.reviewDate}
                location={review.reviewerLocation}
              />
            ))}
          </div>
        </section>
      </section>
    )}
  />
)

GoogleReviews.defaultProps = {
  backgroundColour: "grey-lightest",
}

GoogleReviews.propTypes = {
  backgroundColour: PropTypes.string,
}
