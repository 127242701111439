import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

export const Review = ({ title, summary, location, date }) => (
  <span className="review">
    <div className="review-stars">
      <span className="icon-star"></span>
      <span className="icon-star"></span>
      <span className="icon-star"></span>
      <span className="icon-star"></span>
      <span className="icon-star"></span>
    </div>
    <h3 className="heading-small">{title}</h3>
    <p className="review-summary">{summary}</p>
    <span className="review-source">
      {location}, {dayjs(date).format("MMMM YYYY")}
    </span>
  </span>
)

{
  /* <a
    className="review"
    href="https://www.checkatrade.com/trades/HomeAssistRepairsandServicingHull/reviews"
    target="_blank"
    rel="noreferrer"
  >
    <div className="review-stars">
      <span className="icon-star"></span>
      <span className="icon-star"></span>
      <span className="icon-star"></span>
      <span className="icon-star"></span>
      <span className="icon-star"></span>
    </div>
    <h3 className="heading-small">{title}</h3>
    <p className="review-summary">{summary}</p>
    <span className="review-source">
      {location}, {dayjs(date).format("MMMM YYYY")}
    </span>
  </a> */
}

Review.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}
