import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"

import { BulletListWithIcon, ImageSection } from "../../Shared"
import { smoothScrollToElementWithId } from "../../../utilities"

export const BoilerRepairs = ({ backgroundColour, coverPage, scrollToId }) => (
  <StaticQuery
    query={graphql`
      query BoilerRepairs {
        boilerRepairsSection: contentfulPageSections(
          contentful_id: { eq: "1Uqajy8NPea9CxWC61MNdR" }
        ) {
          contentful_id
          heading
          image {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          bullets
          paragraph {
            paragraph
          }
        }
      }
    `}
    render={(data) => (
      <ImageSection
        blobColour="yellow"
        backgroundColour={backgroundColour}
        image={data.boilerRepairsSection.image.fluid}
        imageDescription={data.boilerRepairsSection.image.title}
      >
        <h2 className="heading-large">{data.boilerRepairsSection.heading}</h2>
        <p>{data.boilerRepairsSection.paragraph.paragraph}</p>
        <h4 className="heading-extra-small">Average boiler repair costs</h4>
        <BulletListWithIcon
          icon="warning"
          bullets={data.boilerRepairsSection.bullets}
        />
        <div className="image-section-buttons">
          {coverPage ? (
            <button
              onClick={() => smoothScrollToElementWithId(scrollToId)}
              className="button-primary"
            >
              Cover your home
            </button>
          ) : (
            <Link to="/home-cover-plans" className="button-primary">
              Cover your home
            </Link>
          )}
          <Link to="/quote" className="button-secondary">
            Get a new boiler
          </Link>
        </div>
      </ImageSection>
    )}
  />
)

BoilerRepairs.defaultProps = {
  backgroundColour: "white",
  coverPage: false,
  scrollToId: "",
}

BoilerRepairs.propTypes = {
  backgroundColour: PropTypes.oneOf(["white", "grey-lightest"]),
  coverPage: PropTypes.bool,
  scrollToId: PropTypes.string,
}
