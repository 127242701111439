import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"

import { BulletListWithIcon, ImageSection } from "../../Shared"

export const CoverYourHome = ({ backgroundColour }) => (
  <StaticQuery
    query={graphql`
      query CoverYourHome {
        coverYourHomeSection: contentfulPageSections(
          contentful_id: { eq: "4dSjt6A1e062LBi2qiBzuV" }
        ) {
          contentful_id
          heading
          image {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          bullets
          paragraph {
            paragraph
          }
        }
      }
    `}
    render={(data) => (
      <ImageSection
        backgroundColour={backgroundColour}
        image={data.coverYourHomeSection.image.fluid}
        imageDescription={data.coverYourHomeSection.image.title}
      >
        <h2 className="heading-large">{data.coverYourHomeSection.heading}</h2>
        <p>{data.coverYourHomeSection.paragraph.paragraph}</p>
        <BulletListWithIcon bullets={data.coverYourHomeSection.bullets} />
        <div className="image-section-buttons">
          <Link to="/home-cover-plans" className="button-primary">
            Cover your home
          </Link>
        </div>
      </ImageSection>
    )}
  />
)

CoverYourHome.defaultProps = {
  backgroundColour: "white",
}

CoverYourHome.propTypes = {
  backgroundColour: PropTypes.oneOf(["white", "grey-lightest"]),
}
