import React from "react"
import PropTypes from "prop-types"

export const IllustratedOptionsSection = ({
  children,
  heading,
  textDirection,
  ...props
}) => (
  <section className="background-grey-lightest" {...props}>
    <div
      className={`wrapper-width wrapper-height illustrated-options-section direction-${textDirection}`}
    >
      <h2 className="heading-large">{heading}</h2>
      <section className="grid-col-4">{children}</section>
    </div>
  </section>
)

IllustratedOptionsSection.defaultProps = {
  textDirection: "left",
}

IllustratedOptionsSection.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  textDirection: PropTypes.oneOf(["left", "centered"]),
}
