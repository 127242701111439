import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import { BulletListWithIcon, BoilerBlob } from "../../Shared"

export const GetANewBoiler = ({ backgroundColour }) => (
  <StaticQuery
    query={graphql`
      query getANewBoiler {
        getANewBoilerSection: contentfulPageSections(
          contentful_id: { eq: "7nA633vl1X7eb3M2uHYUSZ" }
        ) {
          contentful_id
          heading
          image {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          bullets
          paragraph {
            paragraph
          }
        }
      }
    `}
    render={(data) => (
      <section className={`background-${backgroundColour}`}>
        <section className="wrapper-width wrapper-height grid-col-2 image-section">
          <div>
            <BoilerBlob
              photo={data.getANewBoilerSection.image.fluid}
              photoDesc={data.getANewBoilerSection.image.title}
            />
          </div>
          <div className="image-section-content">
            <h2 className="heading-large">
              {data.getANewBoilerSection.heading}
            </h2>
            <p>{data.getANewBoilerSection.paragraph.paragraph}</p>
            <BulletListWithIcon bullets={data.getANewBoilerSection.bullets} />
            <div className="image-section-buttons">
              <Link to="/quote" className="button-primary">
                Get a new boiler
              </Link>
              <Link to="/new-boilers" className="button-secondary">
                Find out more
              </Link>
            </div>
          </div>
        </section>
      </section>
    )}
  />
)

GetANewBoiler.defaultProps = {
  backgroundColour: "white",
}

GetANewBoiler.propTypes = {
  backgroundColour: PropTypes.oneOf(["white", "grey-lightest"]),
}
