import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"

import { BulletListWithIcon, ImageSection, Pill } from "../../Shared"
import { quoteCheckoutURL } from "../../../utilities"

export const YourInstallation = ({
  quoteId,
  boilerId,
  moreInfoNeeded,
  orderExtras,
}) => {
  let formattedOrderExtras = []

  if (orderExtras.length > 0) {
    orderExtras.forEach((extra) => {
      const formattedExtra = `FREE ${extra}`
      formattedOrderExtras.push(formattedExtra)
    })
  }

  return (
    <StaticQuery
      query={graphql`
        query YourInstallationInfo {
          yourInstallationSection: contentfulPageSections(
            contentful_id: { eq: "5KCbFPy5Kmqn0AXsKYTqJQ" }
          ) {
            contentful_id
            heading
            image {
              title
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            bullets
            paragraph {
              paragraph
            }
          }
        }
      `}
      render={(data) => (
        <ImageSection
          image={data.yourInstallationSection.image.fluid}
          imageDescription={data.yourInstallationSection.image.title}
        >
          <h2 className="heading-large">
            {data.yourInstallationSection.heading}
          </h2>
          <p>{data.yourInstallationSection.paragraph.paragraph}</p>
          <Pill text="What's Included" />
          <BulletListWithIcon
            bullets={data.yourInstallationSection.bullets.concat(
              formattedOrderExtras
            )}
          />
          <div className="image-section-buttons">
            {quoteId && !moreInfoNeeded ? (
              <Link
                to={quoteCheckoutURL(quoteId, boilerId)}
                className="button-primary"
              >
                Book your installation
              </Link>
            ) : (
              <Link to="/quote" className="button-primary">
                Get your fixed quote
              </Link>
            )}
            <Link to="/why-us" className="button-secondary">
              Why us?
            </Link>
          </div>
        </ImageSection>
      )}
    />
  )
}

YourInstallation.defaultProps = {
  quoteId: null,
  orderExtras: [],
}

YourInstallation.propTypes = {
  quoteId: PropTypes.string,
  boilerId: PropTypes.string.isRequired,
  moreInfoNeeded: PropTypes.bool.isRequired,
  orderExtras: PropTypes.array,
}
