import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import { smoothScrollToElementWithId } from "../../../utilities"

import BlobWide from "../../../images/Blob-wide.svg"

export const ThermalImaging = ({ coverPage, scrollToId }) => (
  <StaticQuery
    query={graphql`
      query ThermalImaging {
        beforeImage: contentfulAsset(
          contentful_id: { eq: "3dFHW0QFNrwGzlcdQSYwWu" }
        ) {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid
          }
        }
        afterImage: contentfulAsset(
          contentful_id: { eq: "4KazwVpb3GNajPbKxx6ida" }
        ) {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid
          }
        }
        centralHeatingSlug: contentfulServicePages(
          contentful_id: { eq: "30JSveoalCNrX4aqnLsjU8" }
        ) {
          contentful_id
          name
          slug
        }
      }
    `}
    render={(data) => (
      <section className="background-grey-lightest">
        <section className="wrapper-width wrapper-height grid-col-2 image-section direction-text-right thermal-imaging">
          <div className="thermal-imaging-graphic">
            <BlobWide />
            <section className="thermal-imaging-phase">
              <div className="thermal-imaging-phase-dummy">
                <Img
                  className="thermal-imaging-radiator"
                  fluid={data.beforeImage.fluid}
                  alt="Radiator before treatment"
                  title="Radiator before treatment"
                />
                <span className="thermal-imaging-phase-name">
                  Before radiator treament
                </span>
              </div>
              <div className="thermal-imaging-phase-inner">
                <Img
                  className="thermal-imaging-radiator"
                  fluid={data.beforeImage.fluid}
                  alt="Radiator before treatment"
                  title="Radiator before treatment"
                />
                <span className="thermal-imaging-phase-name">
                  Before radiator treament
                </span>
              </div>
            </section>
            <section className="thermal-imaging-phase">
              <div className="thermal-imaging-phase-dummy">
                <Img
                  className="thermal-imaging-radiator"
                  fluid={data.afterImage.fluid}
                  alt="Radiator before treatment"
                  title="Radiator before treatment"
                />
                <span className="thermal-imaging-phase-name">
                  After radiator treament
                </span>
              </div>
              <div className="thermal-imaging-phase-inner">
                <Img
                  className="thermal-imaging-radiator"
                  fluid={data.afterImage.fluid}
                  alt="Radiator before treatment"
                  title="Radiator before treatment"
                />
                <span className="thermal-imaging-phase-name">
                  After radiator treament
                </span>
              </div>
            </section>
          </div>
          <div className="image-section-content">
            <h2 className="heading-large">You don’t know what you can’t see</h2>
            <p>
              Radiators not feeling as hot as they used to? We use cutting-edge
              thermal imaging technology to assess your radiators and repair any
              damage with the best solution.
            </p>
            <p>
              Stay warm this winter and get your heating assessed by Home
              Assist.
            </p>
            <div className="image-section-buttons">
              {coverPage ? (
                <>
                  <button
                    onClick={() => smoothScrollToElementWithId(scrollToId)}
                    className="button-primary"
                  >
                    Cover your home
                  </button>
                  <Link
                    to={`/services/${data.centralHeatingSlug.slug}`}
                    className="button-secondary"
                  >
                    {data.centralHeatingSlug.name} services
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/request-a-callback" className="button-primary">
                    Request a callback
                  </Link>
                  <Link to="/why-us" className="button-secondary">
                    Why us?
                  </Link>
                </>
              )}
            </div>
          </div>
        </section>
      </section>
    )}
  />
)

ThermalImaging.defaultProps = {
  coverPage: false,
  scrollToId: "",
}

ThermalImaging.propTypes = {
  coverPage: PropTypes.bool,
  scrollToId: PropTypes.string,
}
