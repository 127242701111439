import React from "react"
import PropTypes from "prop-types"

export const CardSection = ({
  heading,
  paragraph,
  children,
  additionalClasses,
}) => (
  <section className="background-grey-lightest">
    <section
      className={`wrapper-width wrapper-height card-section${
        additionalClasses ? ` ${additionalClasses}` : ""
      }`}
    >
      <h2 className="heading-large card-section-heading">{heading}</h2>
      <p className="card-section-paragraph">{paragraph}</p>
      <section className="card-section-cards">{children}</section>
    </section>
  </section>
)

CardSection.defaultProps = {
  additionalClasses: "",
}

CardSection.propTypes = {
  additionalClasses: PropTypes.string,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
