import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Pill, Modal } from "../../Shared"
import { coverPlanCheckoutURL } from "../../../utilities"

export const HomeCoverPlans = ({ plans, ...props }) => {
  const [activePlanMobile, setActivePlanMobile] = React.useState()
  const [howItWorksModalVisible, setHowItWorksModalVisibility] = React.useState(
    false
  )

  React.useEffect(() => {
    setActivePlanMobile(plans[0].node.contentful_id)
  }, [])

  return (
    <section
      className="wrapper-width wrapper-height wrapper-height-bottom-only"
      {...props}
    >
      <div className="home-cover-plans-header">
        <h2 className="heading-large">Our plans</h2>
        <p>
          Cover your property with a plan that suits your needs. Need help
          choosing a plan?{" "}
          <a href="tel: 0191 406 0888"> Call us on 0191 406 0888</a>
        </p>
        <section className="home-cover-plans-header-buttons">
          {plans.map(({ node }) => (
            <button
              key={node.contentful_id}
              className={`home-cover-plans-header-button${
                activePlanMobile === node.contentful_id ? " selected" : ""
              }`}
              onClick={() => setActivePlanMobile(node.contentful_id)}
            >
              {node.name}
            </button>
          ))}
        </section>
      </div>
      <table className="home-cover-plans-table">
        <thead>
          <tr className="home-cover-plans-table-header-mobile">
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                colSpan={2}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                <h3>{node.name}</h3>
                <p>{node.shortDescription}</p>
                <Link
                  className="button-primary"
                  to={coverPlanCheckoutURL(node.contentful_id)}
                >
                  Buy now
                </Link>
              </td>
            ))}
          </tr>
          <tr className="home-cover-plans-table-header-desktop">
            <td></td>
            {plans.map(({ node }) => (
              <td key={node.contentful_id}>
                <h3>{node.name}</h3>
                <p>{node.shortDescription}</p>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Monthly price (per property)</td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                <div>
                  <span className="home-cover-plans-table-price">
                    £{node.monthlyPrice}
                  </span>
                  /mo
                </div>
                <Link
                  className="button-primary button-small home-cover-plans-table-buy-now-desktop"
                  to={coverPlanCheckoutURL(node.contentful_id)}
                >
                  Buy now
                </Link>
              </td>
            ))}
          </tr>
          <tr className="home-cover-plans-table-whats-included">
            <td>What's included?</td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              ></td>
            ))}
          </tr>
          <tr>
            <td>Maximum claims per year</td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.maxClaimsPerYear}
              </td>
            ))}
          </tr>
          <tr>
            <td>
              <Pill text="Free" />
              Replacement boiler if your boiler is less than 7 years old and
              cannot be repaired
            </td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.replacementBoilerUnder7Years ? (
                  <span className="icon-tick-circle" />
                ) : (
                  "-"
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>
              <Pill text="50% off" />
              Replacement boiler if your boiler is over 7 years old and cannot
              be repaired
            </td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.replacementBoilerOver7Years ? (
                  <span className="icon-tick-circle" />
                ) : (
                  "-"
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>Boiler &amp; controls repair cover</td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.boilerRepair ? (
                  <span className="icon-tick-circle" />
                ) : (
                  "-"
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>Annual boiler service (CP12)</td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.CP12 ? <span className="icon-tick-circle" /> : "-"}
              </td>
            ))}
          </tr>
          <tr>
            <td>Central heating repairs cover</td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.heatingRepairs ? (
                  <span className="icon-tick-circle" />
                ) : (
                  "-"
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>Plumbing repairs cover</td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.plumbingRepairs ? (
                  <span className="icon-tick-circle" />
                ) : (
                  "-"
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>Electrical repairs cover</td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.electricalRepairs ? (
                  <span className="icon-tick-circle" />
                ) : (
                  "-"
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>
              <Pill text="Free" />
              Magnacleanese system flush to remove sludge in your boiler
            </td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.freeSystemFlush ? (
                  <span className="icon-tick-circle" />
                ) : (
                  "-"
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>
              Default callout fee.{" "}
              <button
                className="home-cover-plans-how-callouts-work-link"
                onClick={() => setHowItWorksModalVisibility(true)}
              >
                How it works
              </button>
            </td>
            {plans.map(({ node }) => (
              <td
                key={node.contentful_id}
                className={
                  activePlanMobile === node.contentful_id ? " selected" : ""
                }
              >
                {node.defaultCalloutFee
                  ? "£" + node.defaultCalloutFee.calloutFee.toFixed(2)
                  : "-"}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <Modal
        isVisible={howItWorksModalVisible}
        dismissFunc={() => setHowItWorksModalVisibility(false)}
        heading="How callout fees work"
      >
        <div className="rich-text">
          <p>
            Some of our plans include callout fees. This means you will be
            charged if an engineer has to visit your property, but, don't worry
            ALL REPAIR COSTS ARE INCLUDED in your monthly price.
          </p>
          <p>
            Don't like the sound of callout fees? No problem! You can reduce
            your callout fee when you buy your plan
          </p>
        </div>
      </Modal>
    </section>
  )
}

HomeCoverPlans.propTypes = {
  plans: PropTypes.array.isRequired,
}
